@import "bootstrap/dist/css/bootstrap.css";

#loading + #app {
	display: none;
}

a {
	color: #1f8bff;
	text-decoration: none;
}

a:hover {
	color: #99caff;
	text-decoration: underline;
}

code {
	color: #ec5fa1;
}

.card-body {
	padding-bottom: 0
}

.card .table {
	font-size: 14px;
	margin-bottom: 0;
}

.card .table td:first-child,
.card .table th:first-child {
	border-left: 0;
}

.card .table td:last-child,
.card .table th:last-child {
	border-right: 0;
}

.card .btn-sm {
	padding-top: 0;
	padding-bottom: 0;
	line-height: 1.4;
}

.interface-list {
	padding: 0;
	list-style: none;
}

.method-list {
	padding: 0;
	list-style: none;
	font-size: .9rem;
	background-color: #2b3647;
}

.method-list a > b {
	color: #fff;
}

.interface-list li {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.header h1,
.header h2,
.header .separator {
	display: inline;
	font-size: 1.4rem;
	line-height: 38px;
}

.badge {
	transition: none;
}

a.badge {
	text-decoration: underline;
}

.no-select {
	user-select: none;
}

body {
	background-color: #161920;
	color: #acacac;
}

.header {
	background-color: #096295;
	border-bottom: 2px solid #67c1f5;
	color: #fff;
}

.search-input:focus,
.search-input {
	border: 1px solid rgba(103, 193, 245, 0.7);
	background-color: #171b22;
	color: #fff;
}

.search-input:focus {
	box-shadow: 0 0 20px rgba(147, 211, 245, 0.50);
}

.search-input::placeholder {
	color: #888;
}

.header a {
	color: inherit;
}

.card {
	background: #22252b;
	color: #e5e5e5;
	border: 0;
	border-radius: 0;
}

.card:target {
	outline: 5px solid #096295;
}

.card-header {
	background: #4e5155;
	border: 0;
	top: 0;
	z-index: 10;
}

.card-header .badge {
	margin-right: .3rem;
}

.card-header .badge-version {
	margin-left: .3rem;
}

.card-inner-header {
	padding: .75rem 1.25rem;
}

.card-method-name {
	color: inherit;
	word-break: break-all;
}

.card hr {
	border-top: 1px solid #4e5155;
}

.card .table {
	color: #acacac;
	border-color: #161920;
}

.card .table>:not(caption)>*>* {
	/* bootstrap 5.3 fix */
	color: unset;
	background: unset;
}

.card .table .custom-control-label {
	color: #ddd;
}

.card .table th,
.card .table td {
	border-color: #161920;
}

.card .table tr:nth-child(odd) td {
	background: #1d2027;
}

.attribute:hover {
	border-bottom-color: rgba(255, 255, 255, 0.4);
}

.card .btn-outline-primary,
.card .form-select,
.card .form-control {
	background-color: #232323;
	border: 1px solid #535354;
	color: #cda869;
	fill: currentColor;
	word-break: break-all;
}

.card .btn-outline-primary:hover {
	background-color: #535354;
}

.card .form-check-input:not(:checked) {
	background-color: #acacac;
}

.card .table .form-control {
	background-color: transparent;
}

.card .table .form-control:focus {
	background-color: rgba(13, 110, 253, 0.25);
	box-shadow: none;
}

.card .table .level-1 td:first-child {
	border-left: 10px solid #22252b;
}

.card .table .level-2 td:first-child {
	border-left: 20px solid #22252b;
}

.card .table .level-3 td:first-child {
	border-left: 30px solid #22252b;
}

.card .table .level-4 td:first-child {
	border-left: 40px solid #22252b;
}

.card .table .level-5 td:first-child {
	border-left: 50px solid #22252b;
}

.prefilled-key {
	color: #acacac;
	font-style: italic;
}

.interface-list-container + .interface-list-container {
	margin-top: 1rem;
}

.interface-group-name {
	margin-bottom: 0.1rem;
	color: #ddd;
	font-weight: bold;
	font-size: 1.2rem;
}

.interface-group-name::marker {
	color: #535354;
}

.interface-group-name:hover::marker,
.interface-group-name:hover {
	color: #fff;
}

.interface-group-name img {
	border-radius: 3px;
	vertical-align: -5px;
}

.interface-list a {
	color: #98caff;
}

.interface-list a:hover {
	color: #fff;
}

.custom-control-label::before {
	background-color: transparent;
}

.list-group-item {
	line-height: 1;
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid #4e5155;
}

.no-email {
	color: hsl(45, 94%, 40%);
	background-color: hsl(46, 100%, 10%);
	border: 0;
	font-size: 13px;
	padding: 5px 10px;
}

.hidden-token,
.hidden-key {
	font-size: 0;
}

.hidden-key:not(:empty):before {
	content: "key=is_here_but_hidden";
	font-size: 1rem;
	color: #997433;
}

.hidden-token:not(:empty):before {
	content: "access_token=is_here_but_hidden";
	font-size: 1rem;
	color: #997433;
}

.add-param-array {
	padding: 0;
	border-radius: 50%;
	height: 26px;
	width: 26px;
	line-height: 26px;
	float: right;
	border: 0;
}

::-webkit-scrollbar {
	height: 16px;
	width: 16px;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(46, 51, 56);
	border: 4px solid transparent;
	border-radius: 8px;
	min-height: 40px;
	background-clip: padding-box;
}

::-webkit-scrollbar-track {
	background-color: rgb(32, 34, 37);
	border: 4px solid transparent;
	border-radius: 8px;
	margin-bottom: 8px;
	background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(132, 134, 137);
}

@media (max-width: 991px) {
	.sidebar {
		height: 200px;
		margin-top: 1.5rem;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		overflow-y: scroll;
		overscroll-behavior: contain;
		-webkit-overflow-scrolling: touch;
	}

	.interface-list a {
		display: block;
		padding: 5px 0;
	}

	.header div[role=search] {
		order: 2;
		margin-top: .5rem;
	}

	.card-method-name {
		display: block;
	}
}

@media (max-width: 575px) {
	.header .separator {
		display: none;
	}

	.header h1,
	.header h2 {
		display: block;
		font-size: 16px;
		line-height: 16px;
	}
}

@media (min-width: 992px) {
	html {
		scrollbar-gutter: stable;
		scroll-padding-top: 54px;
	}

	.header {
		height: 54px;
		position: sticky;
		top: 0;
		z-index: 100;
	}

	.sidebar {
		top: 54px;
		height: calc(100vh - 54px);
		position: sticky;
		overflow-y: scroll;
		scrollbar-width: thin;
		overscroll-behavior: contain;
		-webkit-overflow-scrolling: touch;
	}

	.card-header {
		position: sticky;
		top: 54px;
	}
}
